@import "~leaflet/dist/leaflet.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

/*fix macos annoying styles*/

*:focus {
    outline: none;
}

.modal-buttons {
    text-align: end;
}

.bold {
    font-weight: bold !important;
}

.avatar-company {
    /*color: #fafafa !important;*/
    background-color: #646464 !important;
}

.avatar-offer {
    /*color: #fafafa !important;*/
    background-color: #1976d2 !important;
}

.avatar-contact {
    /*color: #fafafa !important;*/
    background-color: #2eac00 !important;
}

.avatar-admin {
    /*color: #fafafa !important;*/
    background-color: #d928f7 !important;
}

.avatar-flag {
    /*color: #fafafa !important;*/
    background-color: transparent !important;
}


/* remove arrows from input-type number */


/* Chrome, Safari, Edge, Opera */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* Firefox */

input[type=number] {
    -moz-appearance: textfield;
}

/*Text navigation icon style*/
.leaflet-routing-icon {
    background-image: url('./images/leaflet-routing-icons.png');
    -webkit-background-size: 480px 40px;
    background-size: 480px 40px;
    background-repeat: no-repeat;
    margin: 0;
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 40px;
}

.leaflet-routing-icon-continue         { background-position: 0 0; }
.leaflet-routing-icon-sharp-right      { background-position: -40px 0; }
.leaflet-routing-icon-right            { background-position: -80px 0; }
.leaflet-routing-icon-bear-right       { background-position: -120px 0; }
.leaflet-routing-icon-slight-right     { background-position: -120px 0; }
.leaflet-routing-icon-uturn            { background-position: -160px 0; }
.leaflet-routing-icon-sharp-left       { background-position: -200px 0; }
.leaflet-routing-icon-left             { background-position: -240px 0; }
.leaflet-routing-icon-slight-left      { background-position: -240px 0; }
.leaflet-routing-icon-bear-left        { background-position: -280px 0; }
.leaflet-routing-icon-depart           { background-position: -320px 0; }
.leaflet-routing-icon-enter-roundabout { background-position: -360px 0; }
.leaflet-routing-icon-arrive           { background-position: -400px 0; }
.leaflet-routing-icon-via              { background-position: -440px 0; }

.MuiTableRow-root {
    .MuiFormHelperText-root.Mui-error {
        color: #fff;
        background: #f44336;
        position: absolute;
        top: 30px;
        padding: 0.2rem;
        border: 1px solid red;
        border-radius: 0.4rem;
        z-index: 0;
    }
    &.last-table-row{
        .MuiFormHelperText-root.Mui-error {
            bottom: 30px;
            top: unset;
        }
    }
}
.MuiTableCell-head {
    width: auto !important;
}

.MuiTableHead-root {
    position: sticky;
    z-index: 10;
}

.MuiTable-root {
    border-collapse: separate!important;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 1.3rem !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    border: 1px solid #757575 !important;
}
.PrivateDatePickerToolbar-penIcon {
    display: none !important;
}

.PrivateDateTimePickerToolbar-penIcon {
    display: none !important;
}

.image-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.MuiFormHelperText-root.Mui-error {
    z-index: 1 !important;
}

th .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}

th .MuiCheckbox-root {
    padding: 4px;
}

.ChildrenOpacity > * {
    opacity: 0.6;
}

// zvacsenie medzery medzi riadkami tabulky
tr.MuiTableRow-root.MuiTableRow-hover td {
    border-bottom: 2px solid rgba(0,0,0,0.32);
}

// osobitny selector pre prvy riadok dat v tabulke
tr.MuiTableRow-hover:nth-child(2) td {
    border-top: 2px solid rgba(0,0,0,0.62);
}

.ql-container {
    overflow-y: auto;
    resize: vertical;
}